import { t } from '@zupr/i18n'
import { useContext } from 'react'

import Facebook from '../../../../svg/facebook.svg'
import Instagram from '../../../../svg/instagram.svg'
import Twitter from '../../../../svg/twitter.svg'

import DomainContext from '../../../context/domain'
import ModalContext from '../../../context/modal'

import ExternalLink from '../../../shared/router/link-external'

import NewsletterForm from './newsletter/form'

import '../../../../scss/react/components/action-links.scss'
import '../../../../scss/react/features/social-banner.scss'

const Banner = () => {
    const { facebookUrl, twitterUrl, instagramUrl, newsletter } =
        useContext(DomainContext)
    const { openModal } = useContext(ModalContext)

    const handleNewsletter = () => {
        openModal({
            closeButton: true,
            render: (props) => <NewsletterForm {...props} />,
        })
    }

    return (
        <div className="social-banner">
            {newsletter && (
                <div className="social-newsletter">
                    <div className="social-banner-content">
                        <h5>
                            {t('Wil jij graag op de hoogte blijven?', 'dutch')}
                        </h5>
                        <p>
                            {t(
                                'Schrijf je in en ontvang updates over nieuwe winkels en speciale aanbiedingen!',
                                'dutch'
                            )}
                        </p>
                    </div>
                    <div className="social-banner-buttons">
                        <button
                            onClick={handleNewsletter}
                            className="btn btn-small btn-brand"
                        >
                            {t('Meld je aan', 'dutch')}
                        </button>
                    </div>
                </div>
            )}
            {(facebookUrl || twitterUrl || instagramUrl) && (
                <div className="social-media">
                    <div className="social-banner-content">
                        <h5>{t('Volg ons via social media', 'dutch')}</h5>
                        <p>
                            {t(
                                'Blijf op de hoogte van nieuwe winkels en speciale aanbiedingen!',
                                'dutch'
                            )}
                        </p>
                    </div>
                    <div className="social-banner-buttons">
                        {facebookUrl && (
                            <ExternalLink
                                href={facebookUrl}
                                className="btn-social social-facebook"
                            >
                                <Facebook />
                            </ExternalLink>
                        )}
                        {twitterUrl && (
                            <ExternalLink
                                href={twitterUrl}
                                className="btn-social social-twitter"
                            >
                                <Twitter />
                            </ExternalLink>
                        )}
                        {instagramUrl && (
                            <ExternalLink
                                href={instagramUrl}
                                className="btn-social social-instagram"
                            >
                                <Instagram />
                            </ExternalLink>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Banner

import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'

import Indeterminate from '../../../../svg/checkmark-indeterminate.svg'
import Checkmark from '../../../../svg/checkmark.svg'

import '../../../../scss/react/components/radio-checkbox.scss'

interface CheckboxProps {
    field: Field
    onChange?: (ev) => void
    id?: string
    indeterminate?: boolean
    children?: React.ReactNode
    disabled?: boolean
}

const Checkbox = ({
    onChange,
    field,
    id,
    indeterminate,
    children,
    disabled,
}: CheckboxProps) => {
    const checkRef = useRef<HTMLInputElement>(null)

    const handleChange = useCallback(
        (ev) => {
            if (onChange) {
                onChange(ev)
            }
            if (ev.defaultPrevented) return
            if (field.setValue) {
                field.setValue(ev.target.checked)
            }
        },
        [field, onChange]
    )

    const checked = typeof field.value === 'boolean' ? field.value : false
    const showIndeterminate = (!checked && indeterminate) || false // cannot be checked and indeterminate

    useEffect(() => {
        checkRef.current.indeterminate = showIndeterminate
    }, [showIndeterminate])

    return (
        <span
            className={classnames('checkbox', {
                'checkbox-checked': showIndeterminate || checked,
                'checkbox-disabled': disabled,
            })}
        >
            <input
                ref={checkRef}
                type="checkbox"
                id={id || field.name}
                name={field.name}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                key={`${id || field.name}-${checked}`}
            />
            <span className="outline">
                {showIndeterminate && <Indeterminate />}
                {!showIndeterminate && <Checkmark />}
            </span>
            {children}
        </span>
    )
}

export default Checkbox

import { t } from '@zupr/i18n'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import sluggify from 'slug'

import AreaContext, { useDomain } from '../../../context/domain'
import { useBoundingBox } from '../../../hooks/location'
import Trans from '../../../shared/components/trans'
import SiteFull from '../../components/site-full'
import Socials from '../../features/social/banner'
import BuyingOptions from '../../marketing/buying-options/banner'
import Entrepreneurs from '../entrepreneurs/list'
import Locations, { LocationsProps } from './locations'
import Products, { ProductsProps } from './products'

import Biker from '../../../../svg/biker.svg'
import Box from '../../../../svg/box.svg'
import Clock from '../../../../svg/clock.svg'

import '../../../../scss/react/frontoffice/discover-location.scss'
import '../../../../scss/react/frontoffice/usps.scss'
import '../../../../scss/react/pages/discover.scss'

const BeezyHeader = dynamic(() => import('./headers/beezy'))
const DefaultHeader = dynamic(() => import('./headers/default'))
const BigHeader = dynamic(() => import('./headers/bigheader'))

const DiscoverEntrepreneurs = () => {
    const { entrepreneurStories } = useContext(AreaContext)
    const [showcase, setShowcase] = useState([])

    const shuffleArray = (arr) =>
        arr
            .map((a) => [Math.random(), a])
            .sort((a, b) => a[0] - b[0])
            .map((a) => a[1])

    // pick 3 entepeneurs
    useEffect(() => {
        if (!entrepreneurStories) return
        setShowcase(shuffleArray(entrepreneurStories).slice(0, 3))
    }, [entrepreneurStories])

    return (
        <React.Fragment>
            <div className="title">
                <h1>
                    <Trans label="Lokale ondernemers" dutch />
                </h1>
                <Link href="/ondernemers">
                    <a>{t('View all stories')}</a>
                </Link>
            </div>
            <Entrepreneurs stories={showcase} />
        </React.Fragment>
    )
}

const ThemeProducts = ({ theme, ...props }: ProductsProps) => (
    <React.Fragment>
        <div className="title theme">
            <h1>{t(theme, 'themes')}</h1>
            <Link href={sluggify(t(theme, 'themes'))}>
                <a>
                    <Trans label="View all products" />
                </a>
            </Link>
        </div>
        <Products {...props} theme={theme} />
    </React.Fragment>
)

const DiscountProducts = ({ products }: ProductsProps) => (
    <React.Fragment>
        <div className="title">
            <h1>
                <Trans label="Actieproducten" dutch />
            </h1>
            <Link href="/acties">
                <a>
                    <Trans label="View all products" />
                </a>
            </Link>
        </div>
        <Products products={products} />
    </React.Fragment>
)

const DiscoverProducts = ({ products }: ProductsProps) => (
    <React.Fragment>
        <div className="title">
            <h1>
                <Trans label="Ontdek producten" dutch />
            </h1>
            <Link href="/producten">
                <a>
                    <Trans label="View all products" />
                </a>
            </Link>
        </div>
        <Products products={products} />
    </React.Fragment>
)

const DiscoverLocations = ({ locations }: LocationsProps) => (
    <React.Fragment>
        <div className="title">
            <h1>
                <Trans label="Ontdek winkels" dutch />
            </h1>
            <Link href="/winkels">
                <a>
                    <Trans label="View all shops" />
                </a>
            </Link>
        </div>

        <Locations locations={locations} />
    </React.Fragment>
)

interface DiscoverProps {
    locations: LocationsProps['locations']
    products: ProductsProps['products']
    discountProducts: ProductsProps['products']
    themeProducts: ProductsProps['products'][]
}

const Discover = ({
    locations,
    products,
    discountProducts,
    themeProducts,
}: DiscoverProps) => {
    const { brand, themes, slug, data, bigHeader } = useDomain()
    useBoundingBox('box')
    return (
        <SiteFull className="discover-page">
            {!!data?.usps?.length && data.usps.length === 3 && (
                <div className="usps">
                    <ul>
                        {data.usps.map(({ label, icon }) => (
                            <li key={label}>
                                <span>
                                    {icon === 'delivery' && <Biker />}
                                    {icon === 'time' && <Clock />}
                                    {icon === 'products' && <Box />}
                                </span>
                                <p>
                                    {t(
                                        label,
                                        {
                                            productCount:
                                                products?.count?.toLocaleString() ||
                                                '∞',
                                        },
                                        'dutch'
                                    )}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {slug !== 'beezy' && bigHeader && <BigHeader />}
            {slug !== 'beezy' && !bigHeader && <DefaultHeader />}
            {slug === 'beezy' && <BeezyHeader />}

            {data?.discover?.map((part) => (
                <React.Fragment key={part}>
                    {part === 'locations' && (
                        <DiscoverLocations locations={locations} />
                    )}
                    {part === 'products' && (
                        <DiscoverProducts products={products} />
                    )}
                    {part === 'buying-options' && (
                        <BuyingOptions brand={brand} slug={slug} />
                    )}
                    {themes
                        ?.filter((theme) => part === `theme:${theme}`)
                        .map((theme, i) => (
                            <ThemeProducts
                                key={`themeproducts:${theme}`}
                                limit={4}
                                theme={theme}
                                products={themeProducts[i]}
                            />
                        ))}
                    {part === 'entrepreneurs' && <DiscoverEntrepreneurs />}
                    {part === 'socials' && <Socials />}
                    {part === 'discount' && (
                        <DiscountProducts products={discountProducts} />
                    )}
                </React.Fragment>
            ))}
        </SiteFull>
    )
}

export default Discover
